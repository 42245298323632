import React, { createContext, useContext, useReducer } from "react"
import toast from "react-hot-toast"

import { gtmAddToCart, gtmRemoveFromCart } from "@/lib/helpers/tagManager"
import { prepareAttributesForStoreAPI } from "@/lib/helpers/woocommerce"
import { decodeHTMLEntities } from "@/lib/helpers/utils"

const initialState = {
  isLoading: false,
  isOpen: false,
  error: null,
  nonce: null,
  data: { items: [], totals: {} },
  checkout: {}
}

export const CartContext = createContext(initialState)

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_CART":
      return {
        ...state,
        isOpen: !state.isOpen
      }
    case "CLOSE_CART":
      return {
        ...state,
        isOpen: false
      }
    case "REQUEST_CART":
    case "REQUEST_CHECKOUT":
      return {
        ...state,
        isLoading: true
      }
    case "RECEIVE_CART":
      return {
        ...state,
        isLoading: false,
        data: action.payload.body,
        nonce: action.payload.nonce,
        error: null
      }
    case "RECEIVE_CHECKOUT":
      return {
        ...state,
        isLoading: false,
        checkout: action.payload.body,
        nonce: action.payload.nonce,
        error: null
      }
    case "FAILURE_CART":
    case "FAILURE_CHECKOUT":
      return {
        ...state,
        isLoading: false,
        error: action.payload.body,
        nonce: action.payload.nonce
      }
    default:
      return state
  }
}

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const apiUrl = process.env.NEXT_PUBLIC_WP_JSON_API_URL + "wc/store"

  const toggleCart = () => dispatch({ type: "TOGGLE_CART" })
  const closeCart = () => dispatch({ type: "CLOSE_CART" })

  const requestCart = () => dispatch({ type: "REQUEST_CART" })
  const receiveCart = (body, nonce) => {
    return dispatch({ type: "RECEIVE_CART", payload: { body, nonce } })
  }

  const failureCart = (body, nonce) => {
    if (body.message) {
      toast.error(() => (
        <span
          dangerouslySetInnerHTML={{
            __html: body.message
          }}></span>
      ))
    }
    return dispatch({ type: "FAILURE_CART", payload: { body, nonce } })
  }

  const requestCheckout = () => {
    dispatch({ type: "REQUEST_CHECKOUT" })
  }
  const receiveCheckout = (body, nonce) => {
    dispatch({ type: "RECEIVE_CHECKOUT", payload: { body, nonce } })
  }
  const failureCheckout = (body, nonce) => {
    if (body.message) {
      toast.error(() => (
        <span
          dangerouslySetInnerHTML={{
            __html: body.message
          }}></span>
      ))
    }
    dispatch({ type: "FAILURE_CHECKOUT", payload: { body, nonce } })
  }

  /**
   * Fetch cart from StoreAPI
   */
  const fetchCart = async () => {
    return await storeApi("/cart/")
  }

  /**
   * Fetch checkout data from StoreAPI
   */
  const fetchCheckout = async () => {
    return await storeApi(
      "/checkout/",
      {},
      { nonce: state.nonce },
      {
        onRequest: requestCheckout,
        onSuccess: receiveCheckout,
        onFailure: failureCheckout
      }
    )
  }

  /**
   * Add Cart Item
   * @param {int} productId Product or variation ID
   * @param {int} quantity Cart item quantity
   */
  const addCartItem = async (
    product,
    quantity = 1,
    selectedVariations = [],
    customPrice,
    campaignCode = false
  ) => {
    const body = {
      id: product?.id,
      quantity,
      variation: prepareAttributesForStoreAPI(selectedVariations),
      customPrice,
      campaignCode
    }

    return await storeApi(
      "/cart/add-item/",
      { method: "POST", body: JSON.stringify(body) },
      { Nonce: state.nonce }
    ).then((response) => {
      if (!response.message) {
        const { items } = response

        const {
          data: { items: prevItems }
        } = state

        const cartProduct = items.find(
          (item) => item.id == response.added_product_id
        )

        toast.success(
          <span>
            {quantity}x{" "}
            <span className="font-bold">
              {decodeHTMLEntities(cartProduct.name)}
            </span>{" "}
            har lagts i varukorgen
          </span>
        )

        gtmAddToCart(cartProduct, prevItems)
        return true
      }
      return false
    })
  }

  /**
   *  Update cart item
   *
   * @param {string} key Cart item key
   * @param {int} quantity Cart item quantity
   */
  const updateCartItem = async (key, quantity) => {
    const body = {
      key,
      quantity
    }

    return await storeApi(
      "/cart/update-item/",
      { method: "POST", body: JSON.stringify(body) },
      { Nonce: state.nonce }
    ).then((response) => {
      if (!response.message) {
        const { items } = response
        const {
          data: { items: prevItems }
        } = state
        const product = items.find((item) => item.key == key)
        gtmAddToCart(product, prevItems)
      }
    })
  }

  /**
   * Remove cart item
   *
   * @param {string} key Cart item key
   */
  const removeCartItem = async (key) => {
    const body = {
      key
    }

    return await storeApi(
      "/cart/remove-item/",
      { method: "POST", body: JSON.stringify(body) },
      { Nonce: state.nonce }
    ).then(() => {
      const {
        data: { items: prevItems }
      } = state
      const product = prevItems.find((item) => item.key == key)
      gtmRemoveFromCart(product, prevItems)
    })
  }

  /**
   * Clear current cart
   */
  const clearCart = async () => {
    return await storeApi(
      "/cart/items/",
      { method: "DELETE" },
      { Nonce: state.nonce }
    )
  }

  /**
   * Handle StoreApi requests
   *
   * @param {string} endpoint StoreAPI endpoint
   * @param {object} options Request params
   * @param {object} headers Request headers
   */
  const storeApi = async (
    endpoint = "",
    options,
    headers,
    functions = {
      onRequest: requestCart,
      onSuccess: receiveCart,
      onFailure: failureCart
    }
  ) => {
    let status, nonce
    const { onRequest, onSuccess, onFailure } = functions
    onRequest()
    const params = {
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      credentials: "include",
      ...options
    }

    return await fetch(apiUrl + endpoint, params)
      .then((response) => {
        status = response.status
        nonce = response.headers.get("nonce")
        return response.json()
      })
      .then((data) => {
        if (status === 201 || status === 200) {
          onSuccess(data, nonce)
        } else {
          onFailure(data, nonce)
        }
        return data
      })
      .catch((error) => {
        onFailure(error, nonce)
        return error
      })
  }

  return (
    <CartContext.Provider
      value={{
        ...state,
        toggleCart,
        closeCart,
        fetchCart,
        fetchCheckout,
        addCartItem,
        updateCartItem,
        removeCartItem,
        clearCart
      }}>
      {children}
    </CartContext.Provider>
  )
}

export const useCartContext = () => useContext(CartContext)
